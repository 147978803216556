import httpClient  from '.';
const API_CONTROLLER = 'report-project/';

export default {
    getReportSummary(projectId) {
        let url = API_CONTROLLER + 'get-report-summary';
        return httpClient.get(url, {
            params: {
                projectId: projectId,
            }
        });
    },
}